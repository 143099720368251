<template>
  <div class="bg-white">
    <div
      class="fixed inset-0 flex z-40 lg:hidden"
      :class="navParentIsHidden ? 'hidden' : ''"
      role="dialog"
      aria-modal="true"
    >
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
        @after-leave="navParentIsHidden = true"
      >
        <div v-if="!navIsHidden" class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      </transition>

      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-class="translate-x-0"
        leave-to-class="-translate-x-full"
      >
        <div
          v-if="!navIsHidden"
          class="relative max-w-xs w-full bg-gradient-to-b from-white to-gray-100 dark:from-gray-800 dark:to-gray-900 shadow-xl pb-12 flex flex-col overflow-y-auto divide-y divide-gray-200 dark:divide-gray-700"
        >
          <div class="px-4 py-5 flex justify-between">
            <div class="font-medium select-none dark:text-white">
              Outdoor Style Australia
            </div>
            <button
              type="button"
              class="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-200 bg-white dark:bg-gray-800"
              @click="closeNav()"
            >
              <i-heroicons-solid:x />
            </button>
          </div>
          <div class="py-6 px-4 space-y-6 text-gray-800 dark:text-gray-400">
            <div v-for="link in menus" :key="link.name" class="flow-root">
              <NavBarDropDownMobile
                v-if="link.children.length > 0"
                :heading="link.name"
              >
                <router-link
                  :to="link.link"
                  class="text-base font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                >
                  All
                </router-link>
                <span v-for="childLink in link.children" :key="childLink.name">
                  <router-link
                    :to="childLink.link"
                    class="flex items-center font-medium"
                  >{{ childLink.name }}</router-link>
                </span>
              </NavBarDropDownMobile>
              <router-link
                v-else
                :to="link.link"
                class="-m-2 p-2 block font-medium hover:text-gray-900 dark:hover:text-gray-200"
              >
                {{ link.name }}
              </router-link>
            </div>
          </div>
        </div>
      </transition>
    </div>

    <header class="relative z-10">
      <nav aria-label="Top">
        <div
          class="bg-gradient-to-r from-white to-gray-100 dark:from-gray-800 dark:to-gray-900 filter drop-shadow-lg"
        >
          <div class="max-w-7xl mx-auto px-4">
            <div class="h-16 flex items-center justify-between">
              <!-- Logo (lg+) -->
              <div class="hidden lg:flex lg:items-center">
                <router-link to="/">
                  <cgn-organisation-logo class="h-8 w-auto" />
                </router-link>
              </div>

              <div class="hidden h-full lg:flex">
                <!-- Mega menus -->
                <div class="ml-8">
                  <div class="h-full flex justify-center space-x-8">
                    <div v-for="link in menus" :key="link.name" class="flex items-center">
                      <NavBarDropDown
                        v-if="link.children.length > 0"
                        class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                        :heading="link.name"
                      >
                        <router-link
                          :to="link.link"
                          class="text-base font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100 col-span-2"
                        >
                          All
                        </router-link>
                        <span v-for="childLink in link.children" :key="childLink.name">
                          <router-link
                            :to="childLink.link"
                            class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                          >{{ childLink.name }}</router-link>
                        </span>
                      </NavBarDropDown>

                      <router-link
                        v-else
                        :to="link.link"
                        class="flex items-center text-sm font-medium text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-100"
                      >
                        {{ link.name }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Mobile menu and search (lg-) -->
              <div class="flex-1 flex items-center lg:hidden">
                <!-- Mobile menu toggle, controls the 'mobileMenuOpen' state. -->
                <button
                  type="button"
                  class="-ml-2 bg-white dark:bg-gray-800 p-2 rounded-md text-gray-400"
                  @click="openNav()"
                >
                  <span class="sr-only">Open menu</span>
                  <i-heroicons-solid:menu />
                </button>
              </div>

              <!-- Logo (lg-) -->
              <router-link to="/" class="lg:hidden">
                <cgn-organisation-logo class="h-8 w-auto" />
              </router-link>

              <div class="flex-1 flex items-center justify-end">
                <div class="flex items-center gap-2 ml-8">
                  <cgn-dark-mode-switch
                    class="text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-200"
                  />
                  <a href="tel:+61410550488" class="flex gap-1 items-center pl-8 lg:pl-6 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-200">
                    <i-heroicons-solid:phone />
                    <span class="hidden lg:inline">0410 550 488</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  </div>
</template>

<script setup lang="ts">
import { CognitoGroup } from '~cognito/models/Cognito/Group'
import { CognitoMenu } from '~cognito/models/Cognito/Menu'

const navIsHidden = ref(true)
const navParentIsHidden = ref(true)
const openNav = () => {
  navIsHidden.value = false
  navParentIsHidden.value = false
}
const closeNav = () => {
  navIsHidden.value = true
}
const router = useRouter()
router.beforeEach(() => {
  closeNav()
})
const shopGroups = ref<CognitoGroup[]>([])
const loadGroups = async () => {
  const shopdata = await new CognitoGroup().find_many({
    namespace: 'Catalogue',
    model: 'Product',
  })
  shopGroups.value = shopdata
}

const menus = ref<CognitoMenu[]>([])

const loadMenu = async () => {
  menus.value = await new CognitoMenu().getMenu('Main Menu')
}

onMounted(() => {
  loadGroups()
  loadMenu()
})

onServerPrefetch(async () => {
  await loadGroups()
  await loadMenu()
})
</script>
